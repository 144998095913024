<template>
  <div class="chart" ref="chart">
    <div class="country-info d-none" ref="info">
      <p ref="name"></p>
      <p ref="gini"></p>
      <p ref="daly"></p>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "GiniChart",
  props: {
    data: {
      type: Array,
      required: true,
    },
    domainEnd: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      svg: null, // This will hold the SVG reference
      xScale: null,
      yScale: null,
      giniRegression: null,
    };
  },
  async mounted() {
    console.log(this.data);
    const response = await fetch("/gini/giniDalyRegression.json");
    this.giniRegression = await response.json();
    this.createChart();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      // Get new dimensions
      let width = parseInt(d3.select(this.$refs.chart).style("width"));
      let height = parseInt(d3.select(this.$refs.chart).style("height"));

      // Update SVG attributes if necessary
      this.svg.attr("width", width).attr("height", height);
    },
    createChart() {
      // set the dimensions and margins of the graph
      const margin = { top: 30, right: 10, bottom: 50, left: 55 };
      const width = 550 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      let formatThousands = function (d) {
        return d / 1000;
      };

      const that = this;

      // append the svg object to the body of the page
      this.svg = d3
        .select(this.$refs.chart)
        .append("svg")
        .attr("viewBox", `0 0 550 300`) // the larger the last two numbers, the smaller the font size
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add X axis
      this.xScale = d3
        .scaleLinear()
        .domain([20, 60])
        .range([0, width - 20]);
      this.svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(this.xScale));

      // Add X axis label:
      this.svg
        .append("g")
        .append("text")
        .attr("class", "x-axis-label")
        .attr("text-anchor", "end")
        .attr("x", width - 20)
        .attr("y", height + 35)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("Gini Coefficient");

      // Add Y axis for DALYs
      this.yScale = d3
        .scaleLinear()
        .domain(
          this.domainEnd
            ? [10000, this.domainEnd]
            : [10000, d3.max(this.data, (d) => d.daly)]
        )
        .range([height, 0]);
      this.svg
        .append("g")
        .call(d3.axisLeft(this.yScale).tickFormat(formatThousands));

      // Add Y axis label:
      this.svg
        .append("g")
        .append("text")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", "-28px")
        .attr("x", 0)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("DALYs (in 1000s)");

      // Add line for regression (before dots)
      this.giniRegression.forEach(function (regression) {
        that.svg
          .append("path")
          .datum(regression.line)
          .attr("class", "regLine")
          .attr(
            "d",
            d3
              .line()
              .x((d) => that.xScale(d.gini))
              .y((d) => that.yScale(d.daly))
          )
          .style("stroke", function () {
            if (regression.quantile === 0.1) return "#1873d3";
            if (regression.quantile === 0.5) return "#00E6BB";
            if (regression.quantile === 0.9) return "#ff9c00";
          })
          .attr("stroke-width", "2");
      });

      // Add regression label:
      let addRegLabel = function (text, pos, color) {
        that.svg
          .append("g")
          .append("text")
          .attr("text-anchor", "end")
          .attr("y", pos)
          .attr("x", 110)
          .attr("font-size", "12px")
          .attr("font-family", "consolas")
          .attr("fill", color)
          .text(text);
      };
      addRegLabel("Quantile 0.9", 4, "#ff9c00");
      addRegLabel("Quantile 0.5", 20, "#00E6BB");
      addRegLabel("Quantile 0.1", 36, "#1873d3");

      let showTooltip = function (event, d) {
        console.log(d);
        const tooltip = that.$refs.info;
        that.$refs.name.innerText = `${d.country}`;
        that.$refs.gini.innerText = `Gini: ${d.gini}`;
        that.$refs.daly.innerText = `DALYs: ${d.daly.split(".")[0]}`;
        tooltip.classList.remove("d-none");
        tooltip.style.backgroundColor = d.color;
      };

      // Add dots
      this.svg
        .append("g")
        .selectAll("dot")
        .data(this.data)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          return that.xScale(d.gini);
        })
        .attr("cy", function (d) {
          return that.yScale(d.daly);
        })
        .attr("r", 1.5)
        .attr("fill", (d) => {
          if (d.regQ == "0.1") d.color = "#1873d3";
          else if (d.regQ == "0.5") d.color = "#00E6BB";
          else if (d.regQ == "0.9") d.color = "#ff9c00";
          else d.color = "white";
          return d.color;
        })
        .on("mouseover", showTooltip);

      d3.select(this.$refs.chart)
        .on("mouseover", function () {
          d3.selectAll(".regLine").style("opacity", 0.2);
        })
        .on("mouseleave", function () {
          const tooltip = that.$refs.info;
          tooltip.classList.add("d-none");
          d3.selectAll(".regLine").style("opacity", 1);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/palette.scss";

.chart {
  width: 100%;
  height: 100%;
  margin: 1rem auto;
  position: relative;

  .country-info {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    padding: 10px;
    border-radius: 4px;

    p {
      margin-bottom: 0px;
      color: white;
      font-size: 14px;
    }
  }

  .reg-info {
    position: absolute;
    top: 17px;
    left: 90px;
    z-index: 10;
    padding: 10px;
    border-radius: 4px;

    p {
      margin: 0;
      font-family: consolas;
    }
  }
}

:deep(svg) {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
