<template>
  <div class="chart" ref="chart"></div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "VacsChart",
  props: {
    vacsData: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      svg: null, // This will hold the SVG reference
    };
  },
  mounted() {
    this.createChart();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      // Get new dimensions
      let width = parseInt(d3.select(this.$refs.chart).style("width"));
      let height = parseInt(d3.select(this.$refs.chart).style("height"));

      // Update SVG attributes if necessary
      this.svg.attr("width", width).attr("height", height);
    },

    addVaccinationToChart(height, width, xScale) {
      // let tickValues = [
      //   0, 10000000, 20000000, 30000000, 40000000, 50000000, 60000000, 70000000,
      // ];
      let formatMillions = function (d) {
        return d / 1000000;
      };

      // Add Y axis for vacs
      const yScaleVacs = d3
        .scaleLinear()
        .domain([0, 100000000])
        // .domain([0, d3.max(vacsData, (d) => d.cumulatedFirstVac) + 100000000])
        .range([height, 0]);

      this.svg
        .append("g")
        .attr("transform", "translate(" + (width - 50) + ",0)") // places the y-scale on the right
        .attr("class", "yAxis")
        .call(
          d3
            .axisRight(yScaleVacs)
            //      .tickValues(tickValues)
            .tickFormat(formatMillions)
        );

      // Add Y axis label:
      this.svg
        .append("text")
        .attr("text-anchor", "end")
        .attr("x", width - 55)
        .attr("y", +10)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("Vaccinations (millions)");

      // #24F0BB
      const colorArray = ["#47EDC1", "#17C0D4", "#1873d3", "#1237a1"];

      const addVacsArea = (variable, color, labelName) => {
        this.svg
          .append("path")
          .data([this.vacsData])
          .attr("fill", color)
          .attr(
            "d",
            d3
              .area()
              .x(function (d) {
                return xScale(new Date(d.date));
              })
              .y0(yScaleVacs(0))
              .y1(function (d) {
                return yScaleVacs(d[variable]);
              })
          );

        const variablesOrder = [
          "cumulated2ndBooster",
          "cumulated1stBooster",
          "cumulatedSecondVac",
          "cumulatedFirstVac",
        ];

        const currentVarIndex = variablesOrder.indexOf(variable);
        const previousVar =
          currentVarIndex > 0 ? variablesOrder[currentVarIndex - 1] : null;
        const lastDataPoint = this.vacsData[this.vacsData.length - 1]; // Get the last data point for label positioning

        // Determine the bottom and top Y values for the label
        const bottomValue = previousVar ? lastDataPoint[previousVar] : 0; // If there's a previous variable, use it; otherwise, start from 0
        const topValue = lastDataPoint[variable];
        const bottomY = yScaleVacs(bottomValue);
        const topY = yScaleVacs(topValue);
        const labelY = topY + (bottomY - topY) / 2; // Midpoint between topY and bottomY
        const labelX = xScale(new Date(lastDataPoint.date));

        // Add label
        this.svg
          .append("text")
          .attr("x", labelX)
          .attr("y", labelY)
          .attr("font-size", "14px")
          .attr("font-weight", "bold")
          .attr("dy", variable == "cumulatedFirstVac" ? "-0.5em" : "0.35em") // Adjust vertically
          .attr("dx", "-0.35em") // Adjust horizontally
          .attr("fill", variable == "cumulatedFirstVac" ? "#47EDC1" : "white")
          .attr("text-anchor", "end") // Adjust based on the exact label position you prefer
          .text(labelName);
      };

      addVacsArea("cumulatedFirstVac", colorArray[0], "First Vaccine");
      addVacsArea("cumulatedSecondVac", colorArray[1], "Second Vaccine");
      addVacsArea("cumulated1stBooster", colorArray[2], "First Booster");
      addVacsArea("cumulated2ndBooster", colorArray[3], "Second Booster");
    },
    createChart() {
      const margin = { top: 30, right: 10, bottom: 40, left: 55 };
      const width = 550 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      var tickValues = [
        new Date(2020, 6, 1), // 7.2020
        new Date(2021, 0, 1), // 1.2021
        new Date(2021, 6, 1), // 7.2021
        new Date(2022, 0, 1), // 1.2022
        new Date(2022, 6, 1), // 7.2022
        new Date(2023, 0, 1), // 1.2023
        new Date(2023, 6, 1), // 7.2023
        new Date(2024, 0, 1), // 1.2024
      ];

      // append the svg object to the body of the page
      this.svg = d3
        .select(this.$refs.chart)
        .append("svg")
        .attr("viewBox", `0 0 550 300`)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add X axis
      const xScale = d3
        .scaleTime()
        .domain([
          new Date(2020, 6, 1),
          d3.max(this.vacsData, (d) => new Date(d.date)),
        ])
        .range([0, width - 50]);

      this.svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(
          d3
            .axisBottom(xScale)
            .tickValues(tickValues)
            .tickFormat(d3.timeFormat("%m.%Y"))
        );

      if (this.vacsData) {
        this.addVaccinationToChart(height, width, xScale);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/palette.scss";

.chart {
  width: 100%;
  height: 100%;
  margin: 1rem auto;
}

:deep(svg) {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
