<!-- GermanyMap.vue -->
<template>
  <b-container class="container">
    <b-row class="align-items-center">
      <b-col cols="3">
        <div class="legend">
          <p>Legend <br />(7-Day Incidence)</p>
          <div class="legend-item">
            <div
              class="legend-color"
              :style="{ backgroundColor: '#CCE9F5' }"
            ></div>
            <div class="legend-label">0-1</div>
          </div>

          <div
            v-for="(range, index) in legendRanges"
            :key="index"
            class="legend-item"
          >
            <div
              class="legend-color"
              :style="{ backgroundColor: range.color }"
            ></div>
            <div class="legend-label">{{ range.label }}+</div>
          </div>
        </div>
      </b-col>

      <b-col cols="9">
        <div class="map" ref="mapContainer">
          <svg
            ref="map"
            viewBox="0 0 400 550"
            preserveAspectRatio="xMidYMid meet"
            xmlns="http://www.w3.org/2000/svg"
          ></svg>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "MapComponent",
  props: {
    states: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      germanyGeojson: {},
      combinedArray: [],
      colorPalette: [
        "#CCE9F5",
        "#77ECD4",
        "#99F6AC",
        "#D4FD7C",
        "#FFF970",
        "#FFCC66",
        "#FB9320",
        "#dc0000",
        "#a70000",
        "#674EA7",
        "black",
      ],
      colorDomain: [1, 2, 3, 5, 10, 20, 30, 50, 100, 200],
      svg: null,
    };
  },
  async mounted() {
    {
      try {
        const response = await fetch("germany.geojson");
        this.germanyGeojson = await response.json();
      } catch (error) {
        console.error("Error loading JSON data:", error);
      }
    }

    this.combinedArray = this.combineArrays(
      this.germanyGeojson.features,
      this.states,
      "NAME_1",
      "name"
    );
    this.createMap();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    legendRanges() {
      let colors = [...this.colorPalette];
      colors.shift();
      return this.colorDomain.map((threshold, index) => {
        return {
          label: threshold,
          color: colors[index],
        };
      });
    },
  },
  methods: {
    onResize() {
      // Get new dimensions
      let width = parseInt(d3.select(this.$refs.mapContainer).style("width"));
      let height = parseInt(d3.select(this.$refs.mapContainer).style("height"));

      // Update SVG attributes if necessary
      this.svg.attr("width", width).attr("height", height);
    },
    createMap() {
      const self = this;
      let combinedArr = this.combinedArray;
      const width = 400;
      const height = 550;
      // The svg
      this.svg = d3
        .select(this.$refs.map)
        .append("g")
        .attr("transform", `translate(1,20)`);

      this.onResize();

      // Map and projection
      let projection = d3
        .geoMercator()
        .center([10.5, 51.2]) // GPS of location to zoom on
        .scale(2300) // This is like the zoom
        .translate([width / 2, height / 2]);

      let colorScale = d3
        .scaleThreshold()
        .domain(self.colorDomain) // Adjust the domain based on your specific values
        .range(self.colorPalette); // Adjust the range with your desired colors

      let mouseOver = function () {
        d3.selectAll(".Country").transition().duration(200);
        d3.select(this)
          .transition()
          .duration(200)
          .style("stroke-width", 3)
          .style("cursor", "pointer");
      };

      let mouseLeave = function () {
        d3.selectAll(".Country").transition().duration(200);
        d3.select(this).transition().duration(200).style("stroke-width", 0.75);
      };

      let mouseClick = function (d, i) {
        let color = colorScale(i.weekIncidence);
        self.$emit("state-click", i, color);
      };

      // Draw the map
      this.svg
        .selectAll("path")
        .data(combinedArr)
        .enter()
        .append("path")
        // draw each state
        .attr("d", d3.geoPath().projection(projection))
        .attr("fill", function (d) {
          return colorScale(d.weekIncidence);
        })
        .style("stroke", "white")
        .style("stroke-width", 0.75)
        .on("mouseover", mouseOver)
        .on("mouseleave", mouseLeave)
        .on("click", mouseClick);
    },
    combineArrays(array1, array2, commonAttribute1, commonAttribute2) {
      return array1.map((item1) => {
        const matchingItem = array2.find(
          (item2) =>
            item2[commonAttribute2] === item1.properties[commonAttribute1]
        );

        // Merge objects if a match is found
        return matchingItem
          ? { ...item1, weekIncidence: matchingItem.weekIncidence }
          : item1;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/palette.scss";

// show SVG in Safari
/* svg:not(:root) {
  overflow: visible;
}
*/

.legend {
  min-width: 125px;

  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .legend-color {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
    /* Other styling for color box */
  }

  .legend-label {
    font-family: consolas;
  }
}

.map {
  width: 100%;
  height: 100%;
  max-height: 700px;
}
</style>
