<template>
  <div class="chart" ref="chart">
    <div class="country-info d-none" ref="info">
      <p ref="name"></p>
      <p ref="gdp"></p>
      <p ref="daly"></p>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "GDPChart",
  props: {
    data: {
      type: Array,
      required: true,
    },
    domainEnd: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      svg: null, // This will hold the SVG reference
      xScale: null,
      yScale: null,
      gdpRegression: null,
      world: {
        country: "World",
        gdp: "11062",
        daly: "36548",
      },
    };
  },
  async mounted() {
    console.log(this.data);
    const response = await fetch("/gini/gdpDalyReg.json");
    this.gdpRegression = await response.json();
    this.gdpRegression = this.gdpRegression.filter((item) => item.gdp < 70000);
    this.createChart();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      // Get new dimensions
      let width = parseInt(d3.select(this.$refs.chart).style("width"));
      let height = parseInt(d3.select(this.$refs.chart).style("height"));

      // Update SVG attributes if necessary
      this.svg.attr("width", width).attr("height", height);
    },
    createChart() {
      // set the dimensions and margins of the graph
      const margin = { top: 30, right: 10, bottom: 50, left: 55 };
      const width = 550 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      let formatThousands = function (d) {
        return d / 1000;
      };

      const that = this;

      // append the svg object to the body of the page
      this.svg = d3
        .select(this.$refs.chart)
        .append("svg")
        .attr("viewBox", `0 0 550 300`) // the larger the last two numbers, the smaller the font size
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add X axis
      this.xScale = d3
        .scaleLinear()
        .domain([0, 70000])
        .range([0, width - 20]);
      this.svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(this.xScale).ticks(7));

      // Add X axis label:
      this.svg
        .append("g")
        .append("text")
        .attr("class", "x-axis-label")
        .attr("text-anchor", "end")
        .attr("x", width - 20)
        .attr("y", height + 35)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("GDP per capita in $");

      // Add Y axis for DALYs
      this.yScale = d3
        .scaleLinear()
        .domain(
          this.domainEnd
            ? [0, this.domainEnd]
            : [0, d3.max(this.data, (d) => d.daly)]
        )
        .range([height, 0]);
      this.svg
        .append("g")
        .call(d3.axisLeft(this.yScale).tickFormat(formatThousands));

      // Add Y axis label:
      this.svg
        .append("g")
        .append("text")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", "-28px")
        .attr("x", 0)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("DALYs (in 1000s)");

      // Add line for regression (before dots)
      this.svg
        .append("path")
        .attr("class", "line-regression")
        .datum(this.gdpRegression)
        .attr("fill", "none")
        .attr("stroke", "#00E6BB")
        .attr("stroke-width", 3)
        .attr(
          "d",
          d3
            .line()
            .x(function (d) {
              return that.xScale(d.gdp);
            })
            .y(function (d) {
              return that.yScale(d.daly);
            })
        );

      let colorScale = d3
        .scaleLinear()
        .domain([
          0,
          0.3 * d3.max(this.data, (d) => d.daly),
          0.7 * d3.max(this.data, (d) => d.daly),
          d3.max(this.data, (d) => d.daly),
        ])
        .range(["#ffb900", "#ff9c00", "#E52E45", "black"]);

      let showTooltip = function (event, d) {
        console.log(d);
        const tooltip = that.$refs.info;
        that.$refs.name.innerText = `${d.country}`;
        that.$refs.gdp.innerText = `GDP: ${d.gdp.split(".")[0]}$`;
        that.$refs.daly.innerText = `DALYs: ${d.daly.split(".")[0]}`;
        tooltip.classList.remove("d-none");
        tooltip.style.backgroundColor = colorScale(d.daly);
      };
      d3.select(this.$refs.chart).on("mouseleave", function () {
        const tooltip = that.$refs.info;
        tooltip.classList.add("d-none");
      });

      // Add dots
      this.svg
        .append("g")
        .selectAll("dot")
        .data(this.data)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          return that.xScale(d.gdp);
        })
        .attr("cy", function (d) {
          return that.yScale(d.daly);
        })
        .attr("r", 1.5)
        .attr("fill", (d) => {
          return colorScale(d.daly);
        })
        .on("mouseover", showTooltip);

      // Add World dot
      this.svg
        .append("g")
        .append("circle")
        .attr("class", "test")
        .datum(this.world)
        .attr("cx", that.xScale(that.world.gdp))
        .attr("cy", that.yScale(that.world.daly))
        .attr("r", 5)
        .attr("fill", () => {
          that.world.color = colorScale(that.world.daly);
          return that.world.color;
        })
        .on("mouseover", showTooltip);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/palette.scss";

.chart {
  width: 100%;
  height: 100%;
  margin: 1rem auto;
  position: relative;

  .country-info {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    padding: 10px;
    border-radius: 4px;

    p {
      margin-bottom: 0px;
      color: white;
      font-size: 14px;
    }
  }
}

:deep(svg) {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
