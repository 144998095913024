<template>
  <div class="gini mx-auto">
    <b-container class="container">
      <b-row class="content">
        <h2 class="px-0">
          Health and Wealth: DALYs, GDP and the Gini Coefficient
        </h2>
        <b-col class="p-0">
          <p>
            How does the wealth of a nation and the way it is distributed affect
            its population's health?
            <br />To understand this better, we will look at three key
            indicators and the way they are linked.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col md="5" xl="3">
          <div class="paper">
            <p>
              <strong class="green">GDP per capita</strong> measures a country's
              economic output (Gross Domestic Product) and divides it by its
              population. This indicates the average standard of living and
              economic well-being of a country's residents.
            </p>
          </div>
        </b-col>
        <b-col md="7" xl="4">
          <div class="paper">
            <p>
              The <strong class="blue">Gini Coefficient</strong> is a measure of
              income inequality within a population, ranging from 0 to 100. A
              Gini coefficient of 0 indicates perfect equality, where everyone
              has the same income, while a coefficient of 100 indicates maximum
              inequality, where one person has all the income. Empirically, a
              Gini coefficient below 30 is considered to indicate low income
              inequality, while a Gini coefficient above 40 indicates high
              income inequality.
            </p>
          </div>
        </b-col>
        <b-col md="10" xl="5" class="mx-auto">
          <div class="paper">
            <p>
              Disability-Adjusted Life Years (<strong class="orange"
                >DALYs</strong
              >) is a metric used to measure the overall burden of disease. It
              combines the years of life lost due to premature death and the
              years lived in ill-health or with disability. One DALY represents
              one lost year of "healthy" life within a country's population. To
              be able to compare across countries, it is common to express DALYs
              lost per 100,000 inhabitants. We prefer to use the DALY measure
              rather than life expectancy alone, since it additionally accounts
              for the level of health during the individual's life.
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row class="content mb-0">
        <b-col class="p-0">
          <p>
            In each graph, the dots represent individual countries and can be
            hoovered over to get more info.
          </p>
        </b-col>
      </b-row>

      <b-row class="mb-0">
        <b-col lg="7">
          <GDPChart v-if="gdpData" :data="gdpData" domainEnd="100000" />
          <PlaceholderChart v-else />
        </b-col>
        <b-col lg="5" class="d-flex align-items-center">
          <div>
            <p v-html="text.gdp"></p>
            <p v-html="text.gdp2" class="d-none d-xl-block"></p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-3 content">
        <b-col class="p-0">
          <p v-html="text.gdp2" class="d-block d-xl-none"></p>
          <p v-html="text.gdp3" />
          <p v-html="text.gdp4" />
        </b-col>
      </b-row>
      <b-row class="mb-0">
        <b-col lg="7">
          <GiniChart v-if="giniData" :data="giniData" domainEnd="90000" />
          <PlaceholderChart v-else />
        </b-col>
        <b-col lg="5" class="d-flex align-items-center">
          <div>
            <p v-html="text.gini"></p>
            <p v-html="text.gini2" class="d-none d-xxl-block m-xxl-0"></p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-3 content">
        <p v-html="text.gini2" class="p-0 d-block d-xxl-none"></p>
      </b-row>
      <b-row class="mb-0" id="matrix-start">
        <b-col lg="7">
          <Matrix v-if="gdpGiniData" :data="gdpGiniData" ref="matrix" />
          <PlaceholderChart v-else />
        </b-col>
        <b-col lg="5" class="d-flex align-items-center">
          <div>
            <div
              class="legend mb-3 justify-content-center justify-content-lg-start"
            >
              <div class="legend-item">
                <span class="dot dot-blue" />
                <span class="label" data-full="0-30,000" data-short="0-30K" />
              </div>
              <div class="legend-item">
                <span class="dot dot-green" />
                <span class="label" data-full="30,000+" data-short="30K+" />
              </div>
              <div class="legend-item">
                <span class="dot dot-orange" />
                <span class="label" data-full="40,000+" data-short="40K+" />
              </div>
              <div class="legend-item">
                <span class="dot dot-red" />
                <span class="label" data-full="50,000+" data-short="50K+" />
              </div>
            </div>
            <p v-html="text.matrix"></p>
            <p class="d-none d-xl-block m-xl-0" v-html="text.matrix2"></p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-0 content">
        <b-col class="p-0">
          <p v-html="text.matrix2" class="d-block d-xl-none"></p>
          <p>
            First, we examined the impact of income inequality across three GDP
            per capita brackets:
            <strong class="region math" @click="showIncome('low')">
              0-1000$</strong
            >,
            <strong class="region math" @click="showIncome('mid')">
              1000-5000$</strong
            >,
            <strong class="region math" @click="showIncome('high')">
              5000$+</strong
            >.
            <span class="info-text"
              >(You can click on each range to get more info.)</span
            >
            The analysis shows no statistically significant correlation between
            income inequality and health burden for countries in the first and
            third income groups. However, for the 45 countries in the second
            group, there is a statistically significant correlation between the
            Gini coefficient and DALYs of approximately 17.8%.
          </p>
          <p>
            The observed relationship between health and income inequality can
            be attributed to variations in health infrastructure levels across
            countries. In higher-income countries, essential health services
            like basic health infrastructure, sanitation, and vaccination
            programs are widely accessible to the entire population, regardless
            of income. This universal availability means that income
            distribution does not significantly impact overall health outcomes
            in these countries. Several studies on OECD or EEA countries have
            concluded that income inequality in these regions does not
            significantly affect health.
          </p>
          <p>
            Conversely, in lower-income countries, income inequality
            significantly affects overall health levels. Many health measures
            that are publicly available in higher-income countries are often
            accessible only to those who can afford them in lower-income
            countries. Consequently, lower-income individuals in these countries
            are disproportionately affected by the lack of adequate health
            services, leading to a more pronounced impact of income inequality
            on health outcomes.
          </p>
          <p>
            In the 19 countries with a GDP per capita below $1,000, income
            inequality seems to have little impact on health. This could be
            because wealth cannot compensate for the fundamental lack of medical
            facilities and equipment. There may be overall shortages of trained
            medical professionals and unstable supply chains for medical
            supplies and medicine. While the wealthy might be able to travel
            abroad for healthcare, this option does not apply to urgent
            treatment needs.
          </p>
          <p>
            To fully understand the situation, it is crucial to also examine
            regional clusters. The United Nations Regional Groups classification
            divides the world into five regions:
            <strong class="africa region" @click="showRegion('Africa')"
              >Africa</strong
            >,
            <strong class="asia region" @click="showRegion('Asia-Pacific')"
              >Asia and the Pacific</strong
            >,
            <strong class="ee region" @click="showRegion('EE')"
              >Eastern Europe</strong
            >,
            <strong class="latin region" @click="showRegion('Latin America')"
              >Latin America</strong
            >
            and
            <strong
              class="west region"
              @click="showRegion('WE-Others')"
              @blur="showAll"
              >Western Europe and Others</strong
            >.
            <span class="info-text"
              >(You can click on each region to get more info.)</span
            >
          </p>
          <p>
            The graph shows significant regional differences, particularly
            between Latin America and Eastern Europe. In Latin America, many
            countries with relatively higher Gini coefficients still face a
            lower health burden. Conversely, some Eastern European countries,
            despite lower income inequality, seem to suffer from a higher health
            burden, even though both regions fall within a similar income range.
          </p>
          <p>
            Within the five regions, only countries in Eastern Europe show a
            statistically significant impact of income inequality on health,
            with a correlation of 23.8%. This notable impact may be attributed
            to several factors. One key factor is the substantial disparity in
            the quality of care between low-cost public healthcare institutions
            and expensive private medical facilities. Additionally, the
            geographical proximity to Western Europe's high-quality medical
            institutions plays a role. Wealthier individuals in Eastern Europe
            can afford to travel regularly to these institutions for superior
            medical care, exacerbating health disparities between income groups.
          </p>
          <p>
            For completeness, we also examined the correlation between GDP per
            capita and health at regional levels. Only in Africa and Asia does
            GDP per capita have a statistically significant impact on health
            outcomes. These two regions, which have the lowest GDP per capita,
            confirm the trend observed in the first graph: at low GDP levels, an
            increase in GDP positively affects health. However, this positive
            impact diminishes as GDP levels rise.
          </p>
        </b-col>
      </b-row>

      <b-row class="content mb-3">
        <b-col class="p-0">
          <p>
            {{ text.summary }}
          </p>
        </b-col>
      </b-row>

      <b-row class="content mb-3">
        <h3 class="px-0">Notes on the data</h3>
        <b-col class="p-0">
          <p>This section is for number crunching enthusiats.</p>
          <p>
            We obtained data on GDP per capita and the Gini coefficient from the
            <a
              href="https://databank.worldbank.org/reports.aspx?source=2&series=SI.POV.GINI&country="
              >World Bank</a
            >
            database, using GDP per capita at constant 2015 US$ to ensure
            comparability between countries and control for inflation.
            Disability-Adjusted Life Years (DALYs) data was sourced from the
            <a href="https://vizhub.healthdata.org/gbd-compare/"
              >Institute for Health Metrics and Evaluation</a
            >.
          </p>
          <p>
            The year 2021 was chosen as the base year because it is the most
            recent year with available DALYs data. While GDP per capita data for
            2021 was accessible for most countries, the Gini coefficient data
            was not, due to its more complex collection and calculation process.
            Consequently, a Gini estimate was constructed by averaging the Gini
            coefficients from 2015 to 2021. This approach is reasonable, as
            unequal opportunities lead for example to poorer nutrition, limited
            access to preventive care, and unhealthy living conditions, which
            often impact health outcomes over several years.
          </p>
          <p>
            In our data analysis we included countries with a GDP per capita of
            less than $100,000. For the logarithmic regression visualized in the
            first graph this meant 187 different countries. (For aesthetic
            purposes, only countries with a GDP per capita under $70,000 are
            shown in the graph.) The regression analysis, conducted in Python,
            produced the following equation:
            <br />
            <span class="math ms-5">y = -4802ln(x) + 79945</span> <br />
            <span class="math ms-5"> R<sup>2</sup> = 0.3109</span> <br />
            This indicates that approximately 31% of the variation in DALYs can
            be attributed to changes in GDP per capita. While GDP is a
            significant factor, there is substantial variation unexplained by
            GDP alone.
          </p>
          <p>
            For the remaining two graphs, there were 130 countries for which at
            least one value for the Gini coefficient was available in the given
            time period. Several regressions were run in Python using the
            <span class="math">numpy</span> and
            <span class="math">statsmodels</span> libraries. Only the regression
            results with p-values of 0.05 or lower were considered statistically
            significant.
          </p>
          <p>
            When working with data in the social sciences, it is crucial to
            address their reliability. Both the Gini coefficient and DALYs are
            self-reported, which can introduce biases influenced by political
            interests. The accuracy of the Gini coefficient is particularly
            challenging to ensure in countries with large informal sectors, as
            informal employment and income are often underreported. Furthermore,
            in countries with large rural areas where there is limited access to
            healthcare, DALYs might be underreported due to insufficient
            healthcare coverage. Additionally, the methodology for including
            temporary migrants in income and health statistics varies by
            country, further complicating comparisons. It's important to
            consider these factors when interpreting the data, as they can
            significantly impact the perceived levels of inequality and health
            outcomes. To mitigate these issues, using averages over several
            years and increasing the number of data points can help improve the
            robustness and reliability of the analysis.
          </p>
          <p>
            If you are interested in obtaining more details on what techniques
            were used for analysing and visualising the data, please feel free
            to connect with me on
            <a href="https://www.linkedin.com/in/minodora-cristescu/"
              >LinkedIn</a
            >.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import GDPChart from "../components/charts/dalys/GDPChart.vue";
import GiniChart from "../components/charts/dalys/GiniChart.vue";
import Matrix from "../components/charts/dalys/Matrix.vue";
import PlaceholderChart from "@/components/charts/PlaceholderChart.vue";
import text from "@/assets/texts/healthwealth.json";

export default {
  name: "HealthWealth",
  components: { GDPChart, GiniChart, Matrix, PlaceholderChart },
  data() {
    return {
      overallData: null,
      gdpData: null,
      giniData: null,
      gdpGiniData: null,
      text,
    };
  },
  async mounted() {
    try {
      const response = await fetch("/gini/GiniCoefficient.json");
      this.overallData = await response.json();
      this.overallData = this.overallData.filter((item) => item.daly);
      this.gdpData = this.prepGDPData();
      this.prepGiniData();
      this.prepgdpGiniData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    prepGDPData() {
      let data = this.overallData.filter((item) => item.gdp);
      data = data.filter((item) => Number(item.gdp) < "100000");
      return data;
    },
    prepGiniData() {
      this.giniData = this.overallData.filter((item) => item.gini);
      this.giniData = this.giniData.filter((item) => item.gini != "..");
    },
    prepgdpGiniData() {
      this.gdpGiniData = this.overallData.filter((item) => item.gdp);
      this.gdpGiniData = this.gdpGiniData.filter(
        (item) => Number(item.gdp) < "100000"
      );
      this.gdpGiniData = this.gdpGiniData.filter((item) => item.gini);
      this.gdpGiniData = this.gdpGiniData.filter((item) => item.gini != "..");
    },
    handleClickOutside(event) {
      if (!event.srcElement.classList.contains("region")) {
        this.showAll();
      }
    },
    scrollUp() {
      // const isSmallScreen = window.matchMedia("(max-width: 1400px)").matches;
      // if (isSmallScreen) {
      window.scrollTo({
        top: document.getElementById("matrix-start").offsetTop - 80,
        behavior: "smooth",
      });
      // }
    },
    showRegion(region) {
      this.scrollUp();
      this.$refs.matrix.showRegion(region);
    },
    showIncome(level) {
      this.scrollUp();
      this.$refs.matrix.showIncome(level);
    },
    showAll() {
      console.log("blur");
      this.$refs.matrix.showAll();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/palette.scss";

.region {
  cursor: pointer;
  text-decoration: underline;
  color: #9dc4ec;
}

:deep(.green) {
  color: #00e6bb;
}

:deep(.blue) {
  color: #1873d3;
}

:deep(.orange) {
  color: #ff9c00;
}

.math {
  font-family: consolas;
}

.paper {
  margin-bottom: 16px;
  padding: 14px 16px 1px;
  font-family: "Comic Sans MS";
  font-size: 14px;
  line-height: 28px;
  color: #020440;
  background: linear-gradient(#fff9f0 0 10px) 0 0 / 100% 10px no-repeat,
    // 0 0 / 100% 10px means that the background starts at x=0,y=0 and width=100%, height=10px
    repeating-linear-gradient(#9dc4ec 0 1px, #fff9f0 1px 28px) 0 11px / 100%
      calc(100% - 11px);
  //  0 11px / 100% calc(100% - 11px) means that the background starts at x=0,y=11 and width=100%, height=100%-10px
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dot {
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dot-blue {
  height: 8px;
  width: 8px;
  background-color: #1237a1;
}

.dot-green {
  height: 12px;
  width: 12px;
  background-color: #00e6bb;
}

.dot-orange {
  height: 16px;
  width: 16px;
  background-color: #ff9c00;
}

.dot-red {
  height: 20px;
  width: 20px;
  background-color: #dc0000;
}

.legend {
  display: flex;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-family: consolas;
  width: max-content;
}

[data-full]::before {
  content: attr(data-full);
}

/* Media query for screens below 500px */
@media (max-width: 500px) {
  [data-short]::before {
    content: attr(data-short);
  }
}

.info-text {
  font-style: italic;
  color: $m_gray-2;
}
</style>
