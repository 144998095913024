<template>
  <div class="home mx-auto">
    <b-container class="container">
      <div class="content">
        <h2>
          {{ `Today's Overview (${new Date().toLocaleDateString("de-DE")}) ` }}
        </h2>
        <p>
          Here you can view the current 7-day incidence rate of Covid-19 cases
          in Germany, which indicates the number of new infections per 100,000
          residents over the past seven days. By selecting individual states,
          you can access more detailed information about each specific state.
          <br />
          The numbers are obtained from the
          <a href="https://api.corona-zahlen.org/docs/">
            Robert Koch-Institut API
          </a>
          and visualised using D3.js
        </p>
      </div>
      <div v-if="options.length > 0">
        <b-container class="container content">
          <b-row class="d-sm-block d-md-none">
            <b-card title="Germany" class="my-0 mb-0">
              <b-card-body class="px-0 pt-2 pb-0">
                <b-container class="px-0">
                  <b-row class="mb-3">
                    <b-col>
                      Total Cases: <br />
                      {{ formatNum(dataGermany.cases) }}</b-col
                    >
                    <b-col>
                      Total Deaths: <br />
                      {{ formatNum(dataGermany.deaths) }}</b-col
                    >
                    <b-col></b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col>
                      7-Day Incidence: <br />
                      {{ formatNum(roundedWeekIncidence) }}</b-col
                    >
                    <b-col>
                      7-Day Cases: <br />
                      {{ formatNum(latestInfo.cases) }}</b-col
                    >
                    <b-col>
                      7-Day Deaths: <br />
                      {{ formatNum(latestInfo.deaths) }}</b-col
                    >
                  </b-row>
                </b-container>
              </b-card-body>
            </b-card>
          </b-row>

          <b-row class="mb-3 mb-sm-5 mt-3 mt-md-0 align-items-center">
            <b-col md="9" sm="12" class="p-0">
              <GermanyMap @state-click="stateClicked" :states="options" />
            </b-col>
            <b-col md="3" class="d-none d-md-block">
              <div class="germany-card">
                <b-card title="Germany" style="max-width: 20rem" class="mb-2">
                  <b-card-text>
                    <p>
                      Total Cases: <br />
                      {{ formatNum(dataGermany.cases) }}
                    </p>
                    <p>
                      Total Deaths: <br />
                      {{ formatNum(dataGermany.deaths) }}
                    </p>
                    <p>
                      7-Day Incidence: <br />
                      {{ formatNum(roundedWeekIncidence) }}
                    </p>
                    <p>
                      7-Day Cases: <br />
                      {{ formatNum(latestInfo.cases) }}
                    </p>
                    <p>
                      7-Day Deaths: <br />
                      {{ formatNum(latestInfo.deaths) }}
                    </p>
                  </b-card-text>
                </b-card>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <div v-if="selectedOption">
              <div>
                <b-card
                  class="overflow-hidden mx-auto"
                  style="max-width: 700px"
                >
                  <b-row no-gutters>
                    <b-col sm="6" class="state-map d-none d-sm-block">
                      <StateMap :color="stateColor" :geojson="stateGeojson" />
                    </b-col>
                    <b-col sm="6">
                      <b-card-body :title="selectedOption.name" class="px-3">
                        <b-card-text>
                          <p>
                            Total Cases: {{ formatNum(selectedOption.cases) }}
                          </p>
                          <p>
                            Total Deaths: {{ formatNum(selectedOption.deaths) }}
                          </p>
                          <p>
                            7-Day Incidence:
                            {{
                              formatNum(selectedOption.weekIncidence.toFixed(2))
                            }}
                          </p>
                          <p>
                            7-Day Cases:
                            {{ formatNum(selectedOption.delta.cases) }}
                          </p>
                          <p>
                            7-Day Deaths:
                            {{ formatNum(selectedOption.delta.deaths) }}
                          </p>
                          <p>
                            Population:
                            {{ formatNum(selectedOption.population) }}
                          </p>
                        </b-card-text>
                      </b-card-body>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </div>
          </b-row>
        </b-container>
      </div>

      <div class="server-info" v-else>
        <div class="row">
          <div class="column left">
            <div class="loader"></div>
          </div>
          <div class="column right">
            <div v-html="serverInfo" class="message"></div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import api from "../apis/index.js";
import GermanyMap from "@/components/GermanyMap.vue";
import StateMap from "@/components/StateMap.vue";

export default {
  name: "App",
  components: { GermanyMap, StateMap },
  data() {
    return {
      dataGermany: "",
      options: [],
      selectedOption: null,
      stateGeojson: [],
      stateColor: "",
      serverInfo:
        "Please wait while the server loads. We are on a small budget so the first load might take up to one minute. <br />Thank you for your patience &#10084;",
    };
  },
  computed: {
    roundedWeekIncidence() {
      if (this.dataGermany) {
        return this.dataGermany.weekIncidence.toFixed(2);
      } else return "";
    },
    latestInfo() {
      if (this.dataGermany) {
        return this.dataGermany.delta;
      } else return 0;
    },
  },
  async mounted() {
    try {
      // Fetch Germany data
      const responseGermany = await api.get("germany");
      this.dataGermany = responseGermany.data;

      // Fetch states data
      let response = await api.get("states");
      this.options = response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      this.serverInfo =
        "Please reload the webpage. If after reload no content is shown, the server might be idle or unavailable. Please come back later.";
    }
  },
  methods: {
    formatNum(value) {
      if (this.dataGermany) return new Intl.NumberFormat().format(value);
      else return "";
    },
    stateClicked(geojson, color) {
      this.stateGeojson.pop();
      this.stateGeojson.push(geojson);
      this.stateColor = color;
      let found = this.options.find(
        (el) => el.name == geojson.properties.NAME_1
      );
      this.selectedOption = found;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/palette.scss";

.home {
  max-width: $m_content-width-large;

  .germany-card {
    margin-top: 3rem;
  }

  .state-map {
    border-right: 1px solid rgba(0, 0, 0, 0.176);
  }

  .server-info {
    margin: 1rem;

    .message {
      text-align: center;
    }

    .column {
      float: left;
      padding: 10px;
    }

    .left {
      width: 10%;
    }

    .right {
      width: 90%;
    }

    .loader {
      border: 7px solid white;
      border-top: 7px solid $m_orange;
      border-bottom: 7px solid $m_blue-1;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
