import axios from "axios";

const api = axios.create({
  // prod
  baseURL: "https://minodora-health.azurewebsites.net",
  // testing
  //baseURL: "http://localhost:3000",
});

export default api;
