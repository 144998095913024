<!-- GermanyMap.vue -->
<template>
  <div>
    <svg ref="map" width="300" height="300"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "MapComponent",
  props: {
    color: String,
    geojson: Array,
  },
  watch: {
    geojson: {
      handler() {
        d3.select(this.$refs.map).selectAll("*").remove();
        this.createMap();
      },
      deep: true, // Watch for nested changes in the selectedOption object
    },
  },
  data() {
    return {};
  },
  async mounted() {
    this.createMap();
  },
  methods: {
    createMap() {
      // The svg
      let svg = d3.select(this.$refs.map),
        width = +svg.attr("width"),
        height = +svg.attr("height");

      // Calculate the bounding box of the GeoJSON features
      var bounds = d3.geoBounds(this.geojson[0]);

      // Calculate the scale based on the size of the map to be displayed
      var dx = bounds[1][0] - bounds[0][0];
      var dy = bounds[1][1] - bounds[0][1];
      var scale = 35 / Math.max(dx / width, dy / height);

      let coordinates = [...this.geojson[0].centralCoordinates];

      if (document.documentElement.clientWidth < 768) {
        coordinates[0] = coordinates[0] + 0.35;
        scale = 28 / Math.max(dx / width, dy / height);
      }

      // Map and projection
      let projection = d3
        .geoMercator()
        .center(coordinates) // GPS of location to zoom on
        .scale(scale) // This is like the zoom
        .translate([width / 2, height / 2]);

      // Draw the map
      svg
        .append("g")
        .selectAll("path")
        .data(this.geojson)
        .enter()
        .append("path")
        .attr("d", d3.geoPath().projection(projection))
        .attr("fill", this.color);

      // Scroll the window to the bottom
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth", // Smooth scrolling animation
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/palette.scss";
</style>
