<template>
  <div class="chart" ref="chart">
    <svg viewBox="0 0 550 300" xmlns="http://www.w3.org/2000/svg">
      <!-- Bar elements -->
      <rect
        x="160"
        y="160"
        width="40"
        height="100"
        fill="#D2D3D4"
        rx="5"
        ry="5"
      />
      <rect
        x="210"
        y="200"
        width="40"
        height="60"
        fill="#D2D3D4"
        rx="5"
        ry="5"
      />
      <rect
        x="260"
        y="170"
        width="40"
        height="90"
        fill="#D2D3D4"
        rx="5"
        ry="5"
      />
      <rect
        x="310"
        y="110"
        width="40"
        height="150"
        fill="#D2D3D4"
        rx="5"
        ry="5"
      />
      <rect
        x="360"
        y="130"
        width="40"
        height="130"
        fill="#D2D3D4"
        rx="5"
        ry="5"
      />

      <!-- Line graph element -->
      <!-- This assumes that the line points align with the center of each bar -->
      <path
        d="M 170,120 L 230,160 L 280,130 L 330,70 L 390,90"
        stroke="#D2D3D4"
        stroke-width="5"
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "PlaceholderChart",
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/palette.scss";

.chart {
  width: 100%;
  height: 100%;
  margin: 1rem auto;
}

:deep(svg) {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
