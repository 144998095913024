<template>
  <div>
    <div>
      <nav class="navbar fixed-top mx-auto">
        <div class="menu pb-sm-2 pb-md-3">
          <RouterLink to="/"> Home </RouterLink>
        </div>
        <div class="title">Health Visuals</div>
      </nav>
    </div>

    <div class="page">
      <!-- it's a global component when Vue router was installed -->
      <RouterView />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss" scoped>
@import "./assets/palette.scss";

.navbar {
  padding: 1rem;
  background-color: white;
  box-shadow: white 0px 0px 7px;
  justify-content: space-between;
  font-size: larger;

  .menu {
    max-width: $m_page-width;
  }

  a {
    color: $m_blue-1;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      color: $m_orange;
      text-decoration: underline;
    }
  }

  .title {
    color: $m_orange;
    font-weight: 500;
  }
}

.page {
  margin: 5rem 1rem 2rem;
}
</style>
