<template>
  <div class="chart" ref="chart"></div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "CasesTimeChart",
  props: {
    casesData: {
      type: Object,
      required: true,
    },
    vacsData: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    gradient: {
      type: Boolean,
      required: false,
    },
    domainEnd: {
      type: String,
      required: false,
    },
    yAxisTitle: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      svg: null, // This will hold the SVG reference
    };
  },
  mounted() {
    this.createChart();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      // Get new dimensions
      let width = parseInt(d3.select(this.$refs.chart).style("width"));
      let height = parseInt(d3.select(this.$refs.chart).style("height"));

      // Update SVG attributes if necessary
      this.svg.attr("width", width).attr("height", height);
    },
    addCasesToChart(height, xScale) {
      // Add Y axis for cases
      const yScale = d3
        .scaleLinear()
        .domain(
          this.domainEnd
            ? [0, this.domainEnd]
            : [0, d3.max(this.casesData, (d) => d.cases)]
        )
        .range([height, 0]);
      this.svg.append("g").call(d3.axisLeft(yScale));

      // Add Y axis label:
      this.svg
        .append("g")
        .append("text") // append <text> under <g> for cross-browser compatibility
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", this.yAxisTitle.includes("%") ? "-28" : "16")
        .attr("x", 0)
        .attr("fill", this.color)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text(this.yAxisTitle);

      if (this.gradient) {
        // Set the gradient
        this.svg
          .append("linearGradient")
          .attr("id", "line-gradient")
          .attr("gradientUnits", "userSpaceOnUse")
          .attr("x1", 0)
          .attr("y1", yScale(0))
          .attr("x2", 0)
          .attr("y2", yScale(d3.max(this.casesData, (d) => d.cases)))
          .selectAll("stop")
          .data([
            { offset: "0%", color: "#ffb900" },
            { offset: "20%", color: "#ff9c00" },
            { offset: "40%", color: "#E52E45" },
            { offset: "100%", color: "black" },
          ])
          .enter()
          .append("stop")
          .attr("offset", function (d) {
            return d.offset;
          })
          .attr("stop-color", function (d) {
            return d.color;
          });

        const drawVerticalLine = (date, pos) => {
          this.svg
            .append("line") // attach a line
            .style("stroke", "gray") // colour the line
            .style("stroke-width", "2")
            .style("stroke-dasharray", "3, 5")
            .attr("x1", xScale(date)) // x position of the first end of the line
            .attr("y1", function () {
              if (pos == "end") {
                return 20;
              } else return 0;
            }) // y position of the first end of the line
            .attr("x2", xScale(date)) // x position of the second end of the line
            .attr("y2", height - 1); // y position of the second end of the line

          this.svg
            .append("rect")
            .style("fill", "#D2D3D4")
            .style("fill-opacity", "0.25")
            .attr("x", function () {
              if (pos == "start") {
                return 0;
              } else if (pos == "end") {
                return xScale(date);
              } else return 0;
            })
            .attr("y", 0)
            .attr("width", function () {
              if (pos == "start") {
                return xScale(date);
              } else if (pos == "end") {
                return xScale(new Date()) - xScale(date);
              } else return 0;
            })
            .attr("height", height);
        };

        drawVerticalLine(new Date(this.vacsData[0].date), "start");
        drawVerticalLine(new Date("2023-02-28"), "end");
      }

      const addLine = (data, color) => {
        this.svg
          .append("path")
          .data([data])
          .attr("fill", "none")
          .attr("stroke", color)
          .attr("stroke-width", 2)
          .attr(
            "d",
            d3
              .line()
              .x(function (d) {
                return xScale(new Date(d.date));
              })
              .y(function (d) {
                return yScale(d.cases);
              })
          );
      };

      let strokeColor =
        (this.gradient && "url(#line-gradient)") || this.color || "black";

      addLine(this.casesData, strokeColor);
    },
    async addVaccinationToChart(height, width, xScale) {
      let formatMillions = function (d) {
        return d / 1000000;
      };

      // Add Y axis for vacs
      const yScaleVacs = d3
        .scaleLinear()
        .domain([0, 100000000])
        .range([height, 0]);

      this.svg
        .append("g")
        .attr("transform", "translate(" + (width - 50) + ",0)") // places the y-scale on the right
        .attr("class", "yAxis")
        .call(d3.axisRight(yScaleVacs).tickFormat(formatMillions));

      const addVacLine = (label, variable, color, offset) => {
        // add first vac line
        this.svg
          .append("path")
          .data([this.vacsData])
          .attr("fill", "none")
          .attr("stroke", color)
          .attr("stroke-width", 2)
          .attr(
            "d",
            d3
              .line()
              .x(function (d) {
                return xScale(new Date(d.date));
              })
              .y(function (d) {
                return yScaleVacs(d[variable]);
              })
          );

        // Add label
        this.svg
          .append("text")
          .attr("x", width - 55)
          .attr("y", height / 2 + offset)
          .attr("font-size", "13px")
          .attr("font-weight", "bold")
          .attr("fill", color)
          .attr("text-anchor", "end") // Adjust based on the exact label position you prefer
          .text(label);
      };

      // leave await so that the grey area is fully drawn first
      await addVacLine("First Vaccine", "cumulatedFirstVac", "#47EDC1", -38);
      await addVacLine("Second Vaccine", "cumulatedSecondVac", "#17C0D4", -14);

      // Add Y axis label (place after addVacLine so that the grey area is behind)
      this.svg
        .append("text")
        .attr("text-anchor", "end")
        .attr("x", width - 55)
        .attr("y", +10)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("Vaccinations (millions)");
    },
    createChart() {
      const margin = { top: 30, right: 10, bottom: 40, left: 55 };
      const width = 550 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      var tickValues = [
        new Date(2020, 0, 1), // 1.2021
        new Date(2020, 6, 1), // 7.2020
        new Date(2021, 0, 1), // 1.2021
        new Date(2021, 6, 1), // 7.2021
        new Date(2022, 0, 1), // 1.2022
        new Date(2022, 6, 1), // 7.2022
        new Date(2023, 0, 1), // 1.2023
        new Date(2023, 6, 1), // 7.2023
        new Date(2024, 0, 1), // 1.2024
      ];

      // append the svg object to the body of the page
      this.svg = d3
        .select(this.$refs.chart)
        .append("svg")
        .attr("viewBox", `0 0 550 300`)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add X axis
      const xScale = d3
        .scaleTime()
        .domain([
          new Date("2020-01-01"),
          d3.max(this.casesData, (d) => new Date(d.date)),
        ])
        .range([0, width - 50]);

      this.svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(
          d3
            .axisBottom(xScale)
            .tickValues(tickValues)
            .tickFormat(d3.timeFormat("%m.%Y"))
        );

      if (this.vacsData) {
        this.addVaccinationToChart(height, width, xScale);
      }

      this.addCasesToChart(height, xScale);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/palette.scss";

.chart {
  width: 100%;
  height: 100%;
  margin: 1rem auto;
}

:deep(svg) {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
