<template>
  <div class="chart-page">
    <div class="toggle-switch-outside d-sm-none">
      <b-container class="px-0">
        <b-row class="mx-auto my-2 align-items-center">
          <b-col class="px-0 text-end">
            <p class="m-0">1<sup>st</sup> Vaccine</p>
          </b-col>

          <b-col class="switch-container">
            <b-form-checkbox
              v-model="showSecond"
              switch
              @change="updateDotsAndLine"
              class="custom-switch"
            />
          </b-col>

          <b-col class="px-0 text-reg">
            <p class="m-0">2<sup>nd</sup> Vaccine</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="chart mx-auto" ref="chart">
      <div
        class="toggleSwitch d-none d-sm-block"
        :style="{ width: dynamicWidth + 'px' }"
      >
        <b-container class="toggle-container px-0">
          <b-row class="mx-auto">
            <b-col class="text-end">
              <div
                v-if="regressionOutput1"
                @click="onRegInfo(false)"
                :class="{ selected: vaccine === 'cumulatedFirstVac' }"
              >
                <h4 :style="{ fontSize: dynamicFontSizeH4 + 'px' }">
                  First Vaccine
                </h4>
                <p :style="{ fontSize: dynamicFontSizeP + 'px' }">
                  R<sup>2</sup>:
                  {{ Math.round(regressionOutput1.rSquared * 10000) / 10000 }}
                </p>
                <p :style="{ fontSize: dynamicFontSizeP + 'px' }">
                  y = {{ Math.round(regressionOutput1.a * 100) / 100 }}*ln(x) +
                  {{ Math.round(regressionOutput1.b * 100) / 100 }}
                </p>
              </div>
            </b-col>

            <b-col class="switch-container">
              <b-form-checkbox
                v-model="showSecond"
                switch
                @change="updateDotsAndLine"
                class="custom-switch"
              />
            </b-col>

            <b-col class="text-reg">
              <div
                v-if="regressionOutput2"
                @click="onRegInfo(true)"
                :class="{ selected: vaccine === 'cumulatedSecondVac' }"
              >
                <h4 :style="{ fontSize: dynamicFontSizeH4 + 'px' }">
                  Second Vaccine
                </h4>
                <p :style="{ fontSize: dynamicFontSizeP + 'px' }">
                  R<sup>2</sup>:
                  {{ Math.round(regressionOutput2.rSquared * 10000) / 10000 }}
                </p>
                <p :style="{ fontSize: dynamicFontSizeP + 'px' }">
                  y = {{ Math.round(regressionOutput2.a * 100) / 100 }}*ln(x) +
                  {{ Math.round(regressionOutput2.b * 100) / 100 }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div
        class="toggleSwitch small-toggle d-sm-none"
        :style="{ width: dynamicWidth + 'px' }"
      >
        <b-container class="toggle-container px-0">
          <b-row class="mx-auto">
            <b-col class="text-end">
              <div
                v-if="regOutputSelected"
                @click="onRegInfo(false)"
                :class="{ selected: vaccine === 'cumulatedFirstVac' }"
              >
                <h4>{{ selectedVaccineTitle }}</h4>
                <p>
                  R<sup>2</sup>:
                  {{ Math.round(regOutputSelected.rSquared * 10000) / 10000 }}
                </p>
                <p>
                  y = {{ Math.round(regOutputSelected.a * 100) / 100 }}*ln(x) +
                  {{ Math.round(regOutputSelected.b * 100) / 100 }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as d3Reg from "d3-regression";

export default {
  name: "RatioVacsChart",
  props: {
    data: {
      type: Array,
      required: true,
    },
    domainEnd: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      svg: null, // This will hold the SVG reference
      xScale: null,
      yScale: null,
      vaccine: "cumulatedFirstVac",
      regressionOutput1: null,
      regressionOutput2: null,
      regOutputSelected: null,
      selectedVaccineTitle: "First Vaccine",
      regressionPointsFirstVac: null,
      regressionPointsSecondVac: null,
      showSecond: false,
      dynamicFontSizeH4: 16,
      dynamicFontSizeP: 14,
      dynamicWidth: 450,
    };
  },
  mounted() {
    this.runRegressions();
    this.createChart();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    runRegressions() {
      const that = this;
      function createRegression(vaccine, data) {
        let regression = d3Reg
          .regressionLog()
          .x((d) => d[vaccine])
          .y((d) => d.ratioPercentage);
        let regressionOutput = regression(data);
        console.log(regressionOutput);

        let integerValues = [];
        for (let i = 100000; i <= 70000000; i += 250000) {
          integerValues.push(i);
        }

        if (vaccine == "cumulatedFirstVac") {
          that.regressionOutput1 = { ...regressionOutput };
        }
        if (vaccine == "cumulatedSecondVac") {
          that.regressionOutput2 = { ...regressionOutput };
        }

        let a = regressionOutput.a;
        let b = regressionOutput.b;

        function regressionFunction(x) {
          // y=a⋅ln(x)+b
          let y = a * Math.log(x) + b;
          return y;
        }

        let regressionPoints = integerValues.map((x) => ({
          x: x,
          y: regressionFunction(x),
        }));

        return regressionPoints;
      }

      this.regressionPointsFirstVac = createRegression(
        "cumulatedFirstVac",
        this.data
      );

      console.log(this.regressionOutput1.a);
      this.regOutputSelected = this.regressionOutput1;

      let ratio2ndVac = this.data.filter((r) => {
        return r.cumulatedSecondVac != 0;
      });

      this.regressionPointsSecondVac = createRegression(
        "cumulatedSecondVac",
        ratio2ndVac
      );
    },
    onRegInfo(showSec) {
      if (this.showSecond == showSec) return;
      else {
        this.showSecond = !this.showSecond;
        this.updateDotsAndLine();
      }
    },
    updateDotsAndLine() {
      const element = document.querySelector(".small-toggle");
      element.style.opacity = 0;

      let regressionPoints;
      let color;
      let text;
      if (this.vaccine == "cumulatedFirstVac") {
        this.vaccine = "cumulatedSecondVac";
        regressionPoints = this.regressionPointsSecondVac;
        color = "#17C0D4";
        text = "Second Vaccine (millions)";
        setTimeout(() => {
          this.selectedVaccineTitle = "Second Vaccine";
          this.regOutputSelected = this.regressionOutput2;
          element.style.opacity = 1;
        }, 500); // Duration of the fade-out
      } else {
        this.vaccine = "cumulatedFirstVac";
        regressionPoints = this.regressionPointsFirstVac;
        color = "#47EDC1";
        text = "First Vaccine (millions)";
        setTimeout(() => {
          this.selectedVaccineTitle = "First Vaccine";
          this.regOutputSelected = this.regressionOutput1;
          element.style.opacity = 1;
        }, 500); // Duration of the fade-out
      }

      // Update dots
      let dots = this.svg.selectAll("circle").data(this.data);

      // Exit old elements not present in new data
      dots
        .exit()
        .transition()
        .duration(1000)
        .attr("r", 0) // Optional: make them disappear smoothly
        .remove();

      // Enter new elements present in new data
      dots
        .enter()
        .append("circle")
        .attr("r", 0) // Start with radius 0 to grow smoothly
        .merge(dots) // Merge enter and update selection
        .transition()
        .duration(1000)
        .attr("cx", (d) => this.xScale(d[this.vaccine]))
        .attr("cy", (d) => this.yScale(d.ratioPercentage))
        .attr("r", 1)
        .style("fill", "url(#line-gradient)");

      // Update regression line
      let line = d3
        .line()
        .x((d) => this.xScale(d.x))
        .y((d) => this.yScale(d.y));

      this.svg
        .select(".line-regression")
        .datum(regressionPoints)
        .transition()
        .duration(1000)
        .attr("d", line)
        .attr("stroke", color);

      // Select the x-axis label by its class and update its properties
      const label = this.svg.select(".x-axis-label");

      // Fade out
      label
        .transition()
        .duration(500)
        .style("opacity", 0)
        .on("end", () => {
          // Once the fade out is complete, update the text
          label.text(text).transition().duration(500).style("opacity", 1);
        });
    },
    onResize() {
      // Get new dimensions
      let width = parseInt(d3.select(this.$refs.chart).style("width"));
      let height = parseInt(d3.select(this.$refs.chart).style("height"));

      this.dynamicWidth = width * 0.85;
      this.dynamicFontSizeH4 = width / 32;
      this.dynamicFontSizeP = width / 35;

      // Update SVG attributes if necessary
      this.svg.attr("width", width).attr("height", height);
    },
    createChart() {
      // set the dimensions and margins of the graph
      const margin = { top: 30, right: 10, bottom: 50, left: 55 };
      const width = 550 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      let formatMillions = function (d) {
        return d / 1000000;
      };

      // append the svg object to the body of the page
      this.svg = d3
        .select(this.$refs.chart)
        .append("svg")
        .attr("viewBox", `0 0 550 300`) // the larger the last two numbers, the smaller the font size
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add X axis
      this.xScale = d3
        .scaleLinear()
        .domain([0, 70000000])
        .range([0, width - 40]);
      this.svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(this.xScale).tickFormat(formatMillions));

      // Add X axis label:
      this.svg
        .append("g")
        .append("text")
        .attr("class", "x-axis-label")
        .attr("text-anchor", "end")
        .attr("x", width - 35)
        .attr("y", height + 35)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("First Vaccine (millions)");

      // Add Y axis for ratio
      this.yScale = d3
        .scaleLinear()
        .domain(
          this.domainEnd
            ? [0, this.domainEnd]
            : [0, d3.max(this.data, (d) => d.ratioPercentage)]
        )
        .range([height, 0]);
      this.svg.append("g").call(d3.axisLeft(this.yScale).ticks(5));

      // Add Y axis label:
      this.svg
        .append("g")
        .append("text")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", "-28px")
        .attr("x", 0)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("% of Cases resulting in Death");

      const that = this;
      // Add line for regression (before dots)
      this.svg
        .append("path")
        .attr("class", "line-regression")
        .datum(this.regressionPointsFirstVac)
        .attr("fill", "none")
        .attr("stroke", "#47EDC1")
        .attr("stroke-width", 3)
        .attr(
          "d",
          d3
            .line()
            .x(function (d) {
              return that.xScale(d.x);
            })
            .y(function (d) {
              return that.yScale(d.y);
            })
        );

      // Set the gradient for the dots
      this.svg
        .append("linearGradient")
        .attr("id", "line-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("y1", this.yScale(0))
        .attr("x2", 0)
        .attr("y2", this.yScale(d3.max(this.data, (d) => d.ratioPercentage)))
        .selectAll("stop")
        .data([
          { offset: "0%", color: "#ffb900" },
          { offset: "20%", color: "#ff9c00" },
          { offset: "40%", color: "#E52E45" },
          { offset: "100%", color: "black" },
        ])
        .enter()
        .append("stop")
        .attr("offset", function (d) {
          return d.offset;
        })
        .attr("stop-color", function (d) {
          return d.color;
        });

      const vaccine = this.vaccine;
      // Add dots
      this.svg
        .append("g")
        .selectAll("dot")
        .data(this.data)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          return that.xScale(d[vaccine]);
        })
        .attr("cy", function (d) {
          return that.yScale(d.ratioPercentage);
        })
        .attr("r", 1)
        .style("fill", "url(#line-gradient)");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/palette.scss";

.chart-page {
  margin: 1rem auto;
}

.chart {
  width: 100%;
  height: 100%;
  position: relative;
}

.switch-container {
  max-width: 70px;
  align-content: center;
}

.toggleSwitch {
  position: absolute;
  top: 20px;
  right: 17px;
  z-index: 10;
}
.small-toggle {
  transition: opacity 500ms ease-in-out;
  opacity: 1; /* Default state */

  h4 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
}

.toggle-container {
  h4 {
    color: #333; /* Dark grey text */
    font-weight: 600; /* Medium weight */
  }

  p {
    margin: 0;
    color: #555; /* Lighter grey text */
    font-family: math;
  }
}

/* Change the size of the switch  */
:deep(.form-check-input) {
  width: 45px !important;
  height: 25px;
}

/* Change the background color of the switch when it's off (unchecked) */
:deep(.form-check-input) {
  background-color: #47edc1;
  border-color: #47edc1;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

/* Change the background color of the switch when it's on (checked) */
:deep(.form-check-input:checked) {
  background-color: #17c0d4;
  border-color: #17c0d4;
}

:deep(.form-check-input:focus) {
  border: none;
  box-shadow: none;
}

:deep(svg) {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
