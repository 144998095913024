<template>
  <div class="chart" ref="chart">
    <div class="country-info d-none" ref="info">
      <p ref="name"></p>
      <p ref="gdp"></p>
      <p ref="gini"></p>
      <p ref="daly"></p>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "Matrix",
  props: {
    data: {
      type: Array,
      required: true,
    },
    domainEnd: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      svg: null, // This will hold the SVG reference
      xScaleL: null,
      xScaleH: null,
      yScale: null,
      regressionPointsGDP: null,
    };
  },
  mounted() {
    console.log(this.data);
    this.createChart();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      // Get new dimensions
      let width = parseInt(d3.select(this.$refs.chart).style("width"));
      let height = parseInt(d3.select(this.$refs.chart).style("height"));

      // Update SVG attributes if necessary
      this.svg.attr("width", width).attr("height", height);
    },
    createChart() {
      // set the dimensions and margins of the graph
      const margin = { top: 15, right: 10, bottom: 15, left: 10 };
      const width = 550 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      let formatThousands = function (d) {
        return d / 1000;
      };

      const that = this;

      // append the svg object to the body of the page
      this.svg = d3
        .select(this.$refs.chart)
        .append("svg")
        .attr("viewBox", `0 0 550 300`) // the larger the last two numbers, the smaller the font size
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Add X axis for lower
      this.xScaleL = d3
        .scaleLinear()
        .domain([0, 10000])
        .range([10, width / 2]);

      // Add X axis for higher
      this.xScaleH = d3
        .scaleLinear()
        .domain([10000, 100000])
        .range([width / 2, width - 10]);
      // leave code for higher xAxis here so it's before dot
      this.svg
        .append("g")
        .attr("id", "xAxisH")
        .attr("transform", "translate(0,135)")
        .call(d3.axisBottom(this.xScaleH).tickFormat(formatThousands));

      // Add X axis label:
      this.svg
        .append("g")
        .append("text")
        .attr("id", "xLabel")
        .attr("class", "x-axis-label")
        .attr("text-anchor", "end")
        .attr("x", width - 10)
        .attr("y", 130)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("GDP per capita (in 1000$)");

      // Add Y axis for Gini
      this.yScale = d3.scaleLinear().domain([20, 60]).range([height, 0]);

      // Add Y axis label:
      this.svg
        .append("g")
        .append("text")
        .attr("id", "yLabel")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", 280)
        .attr("x", 0)
        .attr("font-size", "14px")
        .attr("font-weight", "500")
        .text("Gini C.");

      let showTooltip = function (event, d) {
        console.log(d);
        const tooltip = that.$refs.info;
        that.$refs.name.innerText = `${d.country}`;
        that.$refs.gdp.innerText = `GDP: ${d.gdp.split(".")[0]}$`;
        that.$refs.gini.innerText = `Gini: ${d.gini}`;
        that.$refs.daly.innerText = `DALY: ${d.daly.split(".")[0]}`;
        tooltip.classList.remove("d-none");
        tooltip.style.backgroundColor = colorScale(d.daly);
      };

      // Add a scale for bubble color
      let colorScale = d3
        .scaleThreshold()
        .domain([30000, 40000, 50000])
        .range(["#1873d3", "#00E6BB", "#ff9c00", "#dc0000"]);

      // Add a scale for bubble size
      let z = d3.scaleLinear().domain([10000, 80000]).range([1, 10]);

      // Add dots and sort data so that smaller dots are on top
      let sortedData = JSON.parse(JSON.stringify(this.data));
      sortedData.sort((a, b) => {
        if (a.daly > b.daly) {
          return -1;
        }
        if (a.daly > b.daly) {
          return 1;
        }
        return 0;
      });
      this.svg
        .append("g")
        .selectAll("dot")
        .data(sortedData)
        .enter()
        .append("circle")
        .attr("cx", function (d) {
          if (d.gdp < 10000) return that.xScaleL(d.gdp);
          else return that.xScaleH(d.gdp);
        })
        .attr("cy", function (d) {
          return that.yScale(d.gini);
        })
        .attr("r", function (d) {
          return z(d.daly); // size of dot
        })
        .style("fill", function (d) {
          return colorScale(d.daly);
        })
        .attr("stroke", "white")
        .on("mouseover", showTooltip);

      // Save a reference to the circles selection
      this.circles = this.svg.selectAll("circle");

      // add axes last so they are over dots
      this.svg
        .append("g")
        .attr("id", "xAxisL")
        .attr("transform", "translate(0,135)")
        .call(d3.axisBottom(this.xScaleL).tickFormat(formatThousands));
      this.svg
        .append("g")
        .attr("id", "yAxis")
        .attr("transform", "translate(265,0)")
        .call(d3.axisLeft(this.yScale));

      let fadeAxis = function () {
        d3.select("#xAxisL").style("opacity", 0.25);
        d3.select("#xAxisH").style("opacity", 0.25);
        d3.select("#yAxis").style("opacity", 0.25);
        d3.select("#xLabel").style("opacity", 0.25);
        d3.select("#yLabel").style("opacity", 0.25);
      };
      let showAxis = function () {
        d3.select("#xAxisL").style("opacity", 1);
        d3.select("#xAxisH").style("opacity", 1);
        d3.select("#yAxis").style("opacity", 1);
        d3.select("#xLabel").style("opacity", 1);
        d3.select("#yLabel").style("opacity", 1);
        const tooltip = that.$refs.info;
        tooltip.classList.add("d-none");
      };
      d3.select(this.$refs.chart)
        .on("mouseover", fadeAxis)
        .on("mouseleave", showAxis);
    },
    showRegion(region) {
      this.circles.style("opacity", (d) => (d.region === region ? 1 : 0.1));
    },
    showIncome(level) {
      if (level == "low") {
        this.circles.style("opacity", (d) => (d.gdp < 1000 ? 1 : 0.1));
      } else if (level == "mid") {
        this.circles.style("opacity", (d) =>
          d.gdp > 1000 && d.gdp < 5000 ? 1 : 0.1
        );
      } else if (level == "high") {
        this.circles.style("opacity", (d) => (d.gdp > 5000 ? 1 : 0.1));
      }
    },
    showAll() {
      this.circles.style("opacity", 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/palette.scss";

.chart {
  width: 100%;
  height: 100%;
  margin: 1rem auto;
  position: relative;
}

.country-info {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  padding: 10px;
  border-radius: 4px;

  p {
    margin-bottom: 0px;
    color: white;
    font-size: 14px;
  }
}

:deep(svg) {
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
